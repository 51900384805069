/* @font-face kit by Fonts2u (http://www.fonts2u.com) */
@font-face {
    font-family: "Pokemon GB";
    src: url("https://dl.dropboxusercontent.com/u/10822923/Pokemon_GB/Pokemon_GB.eot?") format("eot"),
         url("https://dl.dropboxusercontent.com/u/10822923/Pokemon_GB/Pokemon_GB.woff") format("woff"),
         url("https://dl.dropboxusercontent.com/u/10822923/Pokemon_GB/Pokemon_GB.ttf") format("truetype"),
         url("https://dl.dropboxusercontent.com/u/10822923/Pokemon_GB/Pokemon_GB.svg#PokemonGB") format("svg");
    font-weight: normal;
    font-style: normal;
  }
  
  .box {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
    flex-wrap: wrap;
    font-size: 15px;
    min-height: 150px;
    overflow: hidden;
    /* font-family: "Pokemon GB", Arial, sans-serif; */
    border-radius: 2px;
    padding: 8px;
    width: 250px;
    height: 200px;   
    line-height: 16px;
    margin: auto;
    background: white;
    border: 5px solid white;
    box-shadow: 0 1px 0 1px black,
                inset 0 1px 0 1px black,
                0 0 0 1px black,
                inset 0 0 0 1px black;
  }
  .box > i {
    width: 0;
    height: 0;
    border: 5px solid transparent;
    border-top-color: black;
    margin-top: 23px;
    animation: bounce 0.5s ease-in-out 0 infinite alternate;
    float: right;
  }
  
  @keyframes bounce {
    from { margin-top: 20px; }
    to { margin-top: 23px; }
  }

  .button {
    background-color: transparent;
    color: transparent;
    border: none;
    color: black;
    text-align: center;
    transition: all 0.5s;
    cursor: pointer;
  }

  
  .button{
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.1s;
  }
  
  .button:after {
    content: '▶';
    position: absolute;
    opacity: 0;  
    left: -10px;
    transition: 0.5s;
  }
  
  .button:hover{
    padding-right: 8px;
    padding-left:24px;
  }
  
  .button:hover:after {
    opacity: 1;
    left: 10px;
  }


  .phoneversion{
    font-size: 10px;
    border: 6px double black;
    padding: 3px 10px;
    margin: 10px 10px 25px 10px;
    border-radius: 5px;
    background:white;
    line-height: 1.8;
    visibility: collapse;

  }

  .normal{
    letter-spacing: 2.5px;
    font-size: 8px;
    border: 6px double black;
    padding: 3px 10px;
    margin: 10px 10px 25px 10px;
    border-radius: 5px;
    background:white;
    line-height: 1.8;
    width: auto;
  
    
  }

  .pkmn-text {
    letter-spacing: 2.5px;
    font-size: 10px;
    border: 6px double black;
    padding: 3px 10px;
    margin: 10px 10px 25px 10px;
    border-radius: 5px;
    background:white;
    line-height: 1.8;
    
}
